<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumbs :links="breadcrumb" />
    <br />

    <form class="mb-2" @submit.prevent="setPage(1)">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label for="busca" class="block text-sm font-medium mt-1 text-gray-700">Telefone</label>
          <input
            v-model="filtro.telefone"
            type="text"
            name="busca"
            placeholder="Número do telefone"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <label for="qualidade" class="block text-sm font-medium mt-1 text-gray-700">Qualidade</label>
          <v-select
            v-model="filtro.qualidade"
            name="qualidade"
            :options="opcoesQualidade"
            placeholder="Nenhuma específica"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <!-- <div class="col-span-12 md:col-span-3 xl:col-span-2" :class="{ 'animate-fade-down': exibirFiltro.limite }" v-if="exibirFiltro.limite">
          <div class="flex justify-between cursor-pointer" @click="() => (exibirFiltro.limite = false)">
            <label for="limiteMaior" class="block text-sm font-medium text-gray-700">Limite de envio maior que:</label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          </div>

          <input
            v-model="filtro.limiteDeEnvio.maiorIgualA"
            type="number"
            name="limiteMaior"
            placeholder="Nome do template"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <label for="limiteMaior" class="block text-sm font-medium text-gray-700">menor que:</label>
          <input
            v-model="filtro.limiteDeEnvio.menorIgualA"
            type="number"
            name="limiteMaior"
            placeholder="Nome do template"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2 md:mt-10" v-else>
          <div class="flex items-center justify-between cursor-pointer" @click="() => (exibirFiltro.limite = true)">
            <span class="text-sm font-medium text-gray-700">Filtrar por limite de envio</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2" :class="{ 'animate-fade-down': exibirFiltro.quantidade }" v-if="exibirFiltro.quantidade">
          <div class="flex justify-between cursor-pointer" @click="() => (exibirFiltro.quantidade = false)">
            <label for="limiteMaior" class="block text-sm font-medium text-gray-700">Quantidade enviada maior que:</label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          </div>

          <input
            v-model="filtro.quantidadeEnviada.maiorIgualA"
            type="number"
            name="limiteMaior"
            placeholder="Nome do template"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <label for="limiteMaior" class="block text-sm font-medium text-gray-700">menor que:</label>
          <input
            v-model="filtro.quantidadeEnviada.menorIgualA"
            type="number"
            name="limiteMaior"
            placeholder="Nome do template"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2 md:mt-10" v-else>
          <div class="flex items-center justify-between cursor-pointer" @click="() => (exibirFiltro.quantidade = true)">
            <span class="text-sm font-medium text-gray-700">Filtrar por quantidade de envio</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div> -->

        <div class="col-span-12 md:col-span-2">
          <button
            type="submit"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Buscar</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-2">
          <button
            type="button"
            @click="limparFiltros()"
            class="md:mt-7 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Limpar filtros</span>
          </button>
        </div>
      </div>
    </form>

    <div class="mt-16 flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="numeros.length > 0">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Número</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Limite de envios</th>
                  <th scope="col" class="px-2 py-3 mx-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Qualidade</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="numero in numeros" :key="numero._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">{{ numero.telefone }}</td>
                  <td class="text-sm 2xl:text-base font-medium px-2 py-3">
                    {{ numero.limiteDeEnvio }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <span :class="`text-${getQualidade(numero.qualidade)}-500`">{{ numero.qualidade }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination v-model="page" :per-page="filtro.limit" :records="total" @paginate="setPage" class="pagination" />
        </div>
      </div>
      <div v-else>
        <p class="mt-32 text-center font-semibold text-zinc-700">Desculpe, sem resultados {{ ":(" }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "../../components/Breadcrumbs.vue";

export default {
  components: { Breadcrumbs },
  data() {
    return {
      breadcrumb: [{ url: "/numeros", value: "Números WhatsApp" }],
      numeros: [],
      page: 1,
      total: 0,
      exibirFiltro: {
        quantidade: false,
        limite: false
      },
      filtro: {
        limit: 25,
        skip: 0,
        telefone: "",
        qualidade: "",
        quantidadeEnviada: {
          maiorIgualA: 0,
          menorIgualA: 0
        },
        limiteDeEnvio: {
          maiorIgualA: 0,
          menorIgualA: 0
        }
      },
      opcoesQualidade: ["Alta", "Média", "Baixa"],
      route: "/numeros"
    };
  },
  methods: {
    async start() {
      const response = await this.$http.post(`/v1/${this.route}/list`, { ...this.filtro });

      this.numeros = response.data.list;
      this.total = response.data.total;
    },

    async setPage(page) {
      this.page = page;
      this.filtro.skip = (page - 1) * this.filtro.limit;

      await this.start();
    },

    getQualidade(qualidade) {
      if (qualidade.toUpperCase() === "ALTA") {
        return "green";
      }

      if (qualidade.toUpperCase() === "BAIXA") {
        return "red";
      }

      return "yellow";
    },

    getLimite(quantidadeEnviada, limiteTotal) {
      if (quantidadeEnviada <= limiteTotal / 2) {
        return "green";
      }

      if (quantidadeEnviada > limiteTotal / 2 && quantidadeEnviada < limiteTotal - 100) {
        return "blue";
      }

      if (quantidadeEnviada === limiteTotal) {
        return "red";
      }

      return "yellow";
    },

    async limparFiltros() {
      this.filtro = { limit: 25, skip: 0, telefone: "", qualidade: "" };

      await this.setPage(1);
    }
  },
  async beforeMount() {
    await this.start();
  }
};
</script>
<style>
.animate-fade-down {
  animation: fadeDown 0.5s ease-out;
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
